exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blueprint-tsx": () => import("./../../../src/pages/blueprint.tsx" /* webpackChunkName: "component---src-pages-blueprint-tsx" */),
  "component---src-pages-compare-airtable-alternative-tsx": () => import("./../../../src/pages/compare/Airtable-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-airtable-alternative-tsx" */),
  "component---src-pages-compare-confluence-alternative-tsx": () => import("./../../../src/pages/compare/Confluence-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-confluence-alternative-tsx" */),
  "component---src-pages-compare-dovetail-alternative-tsx": () => import("./../../../src/pages/compare/dovetail-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-dovetail-alternative-tsx" */),
  "component---src-pages-compare-enjoy-hq-alternative-tsx": () => import("./../../../src/pages/compare/EnjoyHQ-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-enjoy-hq-alternative-tsx" */),
  "component---src-pages-compare-google-docs-alternative-tsx": () => import("./../../../src/pages/compare/Google-Docs-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-google-docs-alternative-tsx" */),
  "component---src-pages-compare-spreadsheets-alternative-tsx": () => import("./../../../src/pages/compare/spreadsheets-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-spreadsheets-alternative-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-end-user-terms-of-service-tsx": () => import("./../../../src/pages/end-user-terms-of-service.tsx" /* webpackChunkName: "component---src-pages-end-user-terms-of-service-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-free-product-strategy-mini-course-tsx": () => import("./../../../src/pages/free-product-strategy-mini-course.tsx" /* webpackChunkName: "component---src-pages-free-product-strategy-mini-course-tsx" */),
  "component---src-pages-free-trial-tsx": () => import("./../../../src/pages/free-trial.tsx" /* webpackChunkName: "component---src-pages-free-trial-tsx" */),
  "component---src-pages-how-aurelius-improves-ux-research-for-product-teams-tsx": () => import("./../../../src/pages/how-aurelius-improves-ux-research-for-product-teams.tsx" /* webpackChunkName: "component---src-pages-how-aurelius-improves-ux-research-for-product-teams-tsx" */),
  "component---src-pages-how-to-organize-qualitative-research-tsx": () => import("./../../../src/pages/how-to-organize-qualitative-research.tsx" /* webpackChunkName: "component---src-pages-how-to-organize-qualitative-research-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-ui-special-offer-tsx": () => import("./../../../src/pages/landing/ui-special-offer.tsx" /* webpackChunkName: "component---src-pages-landing-ui-special-offer-tsx" */),
  "component---src-pages-letter-tsx": () => import("./../../../src/pages/letter.tsx" /* webpackChunkName: "component---src-pages-letter-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-purchase-tsx": () => import("./../../../src/pages/purchase.tsx" /* webpackChunkName: "component---src-pages-purchase-tsx" */),
  "component---src-pages-responsible-disclosure-tsx": () => import("./../../../src/pages/responsible-disclosure.tsx" /* webpackChunkName: "component---src-pages-responsible-disclosure-tsx" */),
  "component---src-pages-security-policy-tsx": () => import("./../../../src/pages/security-policy.tsx" /* webpackChunkName: "component---src-pages-security-policy-tsx" */),
  "component---src-pages-solutions-ux-design-tsx": () => import("./../../../src/pages/solutions/ux-design.tsx" /* webpackChunkName: "component---src-pages-solutions-ux-design-tsx" */),
  "component---src-pages-solutions-ux-research-operations-tsx": () => import("./../../../src/pages/solutions/ux-research-operations.tsx" /* webpackChunkName: "component---src-pages-solutions-ux-research-operations-tsx" */),
  "component---src-pages-solutions-ux-research-tsx": () => import("./../../../src/pages/solutions/ux-research.tsx" /* webpackChunkName: "component---src-pages-solutions-ux-research-tsx" */),
  "component---src-pages-subprocessors-tsx": () => import("./../../../src/pages/subprocessors.tsx" /* webpackChunkName: "component---src-pages-subprocessors-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-user-research-insights-and-nuggets-tsx": () => import("./../../../src/pages/user-research-insights-and-nuggets.tsx" /* webpackChunkName: "component---src-pages-user-research-insights-and-nuggets-tsx" */),
  "component---src-pages-user-research-qualitative-data-tsx": () => import("./../../../src/pages/user-research-qualitative-data.tsx" /* webpackChunkName: "component---src-pages-user-research-qualitative-data-tsx" */),
  "component---src-pages-user-research-repository-tsx": () => import("./../../../src/pages/user-research-repository.tsx" /* webpackChunkName: "component---src-pages-user-research-repository-tsx" */),
  "component---src-pages-user-research-synthesis-and-analysis-tsx": () => import("./../../../src/pages/user-research-synthesis-and-analysis.tsx" /* webpackChunkName: "component---src-pages-user-research-synthesis-and-analysis-tsx" */),
  "component---src-pages-ux-user-research-interview-guide-tsx": () => import("./../../../src/pages/ux-user-research-interview-guide.tsx" /* webpackChunkName: "component---src-pages-ux-user-research-interview-guide-tsx" */),
  "component---src-pages-videos-1-tsx": () => import("./../../../src/pages/videos/1.tsx" /* webpackChunkName: "component---src-pages-videos-1-tsx" */),
  "component---src-pages-videos-2-tsx": () => import("./../../../src/pages/videos/2.tsx" /* webpackChunkName: "component---src-pages-videos-2-tsx" */),
  "component---src-pages-videos-3-tsx": () => import("./../../../src/pages/videos/3.tsx" /* webpackChunkName: "component---src-pages-videos-3-tsx" */)
}

